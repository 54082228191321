export const environment = {
  production: false,
  apiUrl:'https://fd.fareboutique.com/v1/',
  url : 'https://fd.fareboutique.com/',
  url_booking_cancel : 'https://fabdev.fareboutique.com/',
  hotel_booking_url : 'https://fd1.fareboutique.com/',
  vehicle_transportation_url : 'https://dev-vehicletransport.flightapi.co.in/',
  imageurl:'https://fd.fareboutique.com/uploads/userprofile/',
  airlineurl:'https://fd.fareboutique.com/uploads/airlines/',
  // adminPath:'/admin',
  adminPath:'',
  firebase: {
    apiKey: "AIzaSyCYD9XULCFhZ85ITW2I_pH67ZxQG0Lh-6A",
    authDomain: "angularnoti-5224a.firebaseapp.com",
    projectId: "angularnoti-5224a",
    storageBucket: "angularnoti-5224a.appspot.com",
    messagingSenderId: "1015037219900",
    appId: "1:1015037219900:web:ed895e3899d80ec256df39",
    measurementId: "G-T1JKFBCXQ5",
    vapidKey: 'BB0DjFt-0d_n8Pg3aEPnGjn3NRAkFrEzAAP7tQl0xLHZUbo9joPkvXmazhQUcRDIrkdxK3DlHS988deNwevgg6Y'
  },
  lg: {
    pnr:"PNR",
    currency:"fa fa-inr",
    zipcode:"Zipcode",
    isUrl: "ind",
    checkin_ad_Baggage:"15",
    checkin_chd_Baggage:"15",
    checkin_inf_Baggage:"0",

    cabin_ad_Baggage:"7",
    cabin_chd_Baggage:"7",
    cabin_inf_Baggage:"7",
  },
  /* if we build for client use main domain change adminPath "/admin"
   otherwise blank adminPath use for localhost //and online use other domain change according to your folder path*/
};


// =====================itly code for live=========================
// export const environment = {
//   production: false,
//   apiUrl:'https://ita.flightapi.co.in/v1/',
//   url : 'https://ita.flightapi.co.in/',
//   url_booking_cancel : 'https://fabdev.fareboutique.com/',
//   hotel_booking_url : 'https://fd1.fareboutique.com/',
//   vehicle_transportation_url : 'https://dev-vehicletransport.flightapi.co.in/',
//   imageurl:'https://ita.flightapi.co.in/uploads/userprofile/',
//   airlineurl:'https://ita.flightapi.co.in/uploads/airlines/',
//   // adminPath:'/admin',
//   adminPath:'',
//   firebase: {
//     apiKey: "AIzaSyCYD9XULCFhZ85ITW2I_pH67ZxQG0Lh-6A",
//     authDomain: "angularnoti-5224a.firebaseapp.com",
//     projectId: "angularnoti-5224a",
//     storageBucket: "angularnoti-5224a.appspot.com",
//     messagingSenderId: "1015037219900",
//     appId: "1:1015037219900:web:ed895e3899d80ec256df39",
//     measurementId: "G-T1JKFBCXQ5",
//     vapidKey: 'BB0DjFt-0d_n8Pg3aEPnGjn3NRAkFrEzAAP7tQl0xLHZUbo9joPkvXmazhQUcRDIrkdxK3DlHS988deNwevgg6Y'
//   },
//   lg: {
//     pnr:"Flight id",
//     currency:"fa fa-eur",
//     zipcode:"CAP (Zipcode)",
//     isUrl: "ita",
//     checkin_ad_Baggage:"2Pcs 23+23 Kg",
//     checkin_chd_Baggage:"2Pcs 23+23 Kg",
//     checkin_inf_Baggage:"10 Kg",

//     cabin_ad_Baggage:"7 Kg",
//     cabin_chd_Baggage:"7 Kg",
//     cabin_inf_Baggage:"0 Kg",
//   },
// };
