import { CanActivate, Router } from '@angular/router';

import { CommonService } from './commonservice.service';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
  })
  export class HomeGuardService implements CanActivate {
	constructor(
	  public auth: CommonService,
	  public router: Router
	) {}
	canActivate(): boolean {
	  if (!this.auth.getToken()) {
		this.router.navigateByUrl('/home');
		return true;
	  }
	  this.router.navigateByUrl('/home');
	  return false;
	}
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    public auth: CommonService,
    public router: Router
  ) {}
  canActivate(): boolean {
    if (!this.auth.getToken()) {
      this.router.navigateByUrl('/auth/login');
      return false;
    }
    return true;
  }
}

