import { AuthGuardService, HomeGuardService } from './services';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },{
    path: 'auth',
    children: [
      { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
    ]
  },{
    path: '',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./home-dashboard.module').then(m => m.HomedashboardModule)
      }
    ]
  },{
      path: 'clientdashboard',
      canActivate: [AuthGuardService],
      children:[
        { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
      ]
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
